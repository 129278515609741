<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="UN/DOT Acceptance Procedure" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/Testing/dot_flow.gif"
          width="400"
          height="1098"
          alt=""
          title="dot_flow"
          class="img-responsive wp-image-12145"
        />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            The acceptance procedure is used to determine whether a new product,
            as offered for transport, is a candidate for Class 1. In this
            context, a new product is one which, in the opinion of the competent
            authority, involves any of the following:
          </p>
          <ul>
            <li>
              A new substance or a combination of mixture of substances,
              intended to function as an explosive or pyrotechnic, which is
              considered to be significantly different from other combinations
              or mixtures already classified
            </li>
            <li>
              A new substance or article, not intended for explosive use, which
              has, or is suspected of having, explosive properties
            </li>
            <li>
              A new design of article containing an explosive substance or an
              article containing a new explosive substance or a new combination
              or mixture of explosive substances
            </li>
            <li>
              A new design of package for an explosive substance or article
              including a new type of inner packaging or a new arrangement of
              articles (a relatively minor change to an inner or outer packaging
              can be critical and can convert a lesser risk into a mass
              explosion risk).
            </li>
          </ul>
          <p>
            The classification procedure should be undertaken before a new
            product is offered for transport. The producer, or other applicant
            for the classification of a new product, should provide adequate
            information concerning the names and characteristics of all
            explosive substances in the product and should furnish the results
            of all relevant tests that have been done.
          </p>
          <p>
            The flow chart outlines the DOT Procedure for assignment of a new
            substance or article to a division of
            <router-link
              style="color:#aa222c;"
              to="/testing/dottesting/tests-for-class-1-explosives"
              >Class 1</router-link
            >.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "DOT Classification Procedure",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "New products intended to function as explosives, new products with suspected explosive properties, and products that have been altered in any way must undergo a DOT classification procedure."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
